import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Form, json, redirect, useLoaderData, useOutletContext} from "react-router-dom";
import {useEffect, useState} from "react";
import {Col, FormControl, FormGroup, FormLabel, Row, Table} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";


export const settingsAdminLoader = (getAuth) => async () => {
    console.log("settings admin loader")
    try {
        let auth = await getAuth();
        let artist = auth.username === "jockerse" ? "erika" : auth.username;
        let response = await fetch(`https://botanical.ink/api/admin/${artist}/settings`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.idToken.getJwtToken()}`,
            },
        });
        if (response.ok) {
            return await response.json();
        } else {
            return json({
                error: response.status
            })
        }
    } catch (error) {
        console.log(error)
        if (error === "NOT VALID") {
            return redirect("/login")
        } else {
            throw error
        }
    }
}
export const SettingsAdmin = () => {
    const outletContext = useOutletContext();
    const loaderData = useLoaderData();
    const [settings, setSettings] = useState()

    useEffect(() => {
        setSettings(loaderData);
    }, [loaderData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(settings);
    }

    const handleInputChange = (field, value) => {
        setSettings(prev => {
            let newSettings = prev;
            newSettings[field] = value;
            return newSettings;
        })
    }

    return (
        <Container>

            <Form
                onSubmit={handleSubmit}
                className={`d-grid rounded column-gap-3 row-gap-3 mb-3 ${outletContext.darkMode ? "text-light" : "text-black"}`}>
                <Row>
                    <Col className={"d-flex justify-content-center"}>
                        <h3>Appointment Settings</h3>
                    </Col>
                </Row>
                {settings &&
                    <>
                        <Row className={`d-flex justify-content-center`}>
                            <FormGroup as={Col} className={""}>
                                <FormLabel>Username</FormLabel>
                                <FormControl className={`${outletContext.darkMode ? "text-light" : "text-black"}`}
                                             name={"username"} defaultValue={settings.username} plaintext readOnly/>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <FormLabel>Full Name</FormLabel>
                                <FormControl className={`${outletContext.darkMode ? "text-light" : "text-black"}`}
                                             name={"fullName"} defaultValue={settings.fullName} plaintext readOnly/>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <FormLabel>Email</FormLabel>
                                <FormControl className={`${outletContext.darkMode ? "text-light" : "text-black"}`}
                                             name={"email"} defaultValue={settings.email} plaintext readOnly/>
                            </FormGroup>

                        </Row>
                        <Row>
                            <Col>
                                <h4>Books</h4>
                            </Col>
                        </Row>
                        <Row className={""}>
                            <FormGroup as={Col} xs={6}>
                                <FormLabel>Books Open Until</FormLabel>
                                <FormControl name={"booksOpenUntil"} value={settings.booksOpenUntil} type={"date"}
                                             onChange={e => handleInputChange("date", e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup as={Col} xs={6}>
                                <FormLabel>Open Buffer Hours</FormLabel>
                                <FormControl name={"openBufferHours"} value={settings.openBufferHours} type={"number"}
                                             onChange={e => handleInputChange("openBufferHours", e.target.value)}/>
                            </FormGroup>
                        </Row>
                        <Row>
                            <Col>
                                <Table></Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h4>Limits</h4>
                            </Col>
                        </Row>
                        <Row xs={2} className={"row-gap-3"}>
                            <FormGroup as={Col}>
                                <FormLabel>Max Tattoo Hours</FormLabel>
                                <FormControl name={"maxTattooHours"} value={settings.maxTattooHours} type={"number"}
                                             onChange={e => handleInputChange("maxTattooHours", e.target.value)}/>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <FormLabel>Max Appt. Hours</FormLabel>
                                <FormControl name={"maxAppointmentLength"} value={settings.maxAppointmentLength}
                                             type={"number"}
                                             onChange={e => handleInputChange("maxAppointmentLength", e.target.value)}/>
                            </FormGroup>
                            <FormGroup as={Col}>
                                <FormLabel>Buddy Buffer Minutes</FormLabel>
                                <FormControl name={"buddyBufferMinutes"} value={settings.buddyBufferMinutes}
                                             type={"number"}
                                             onChange={e => handleInputChange("buddyBufferMinutes", e.target.value)}/>
                            </FormGroup>
                        </Row>

                        <Row>
                            <Col>
                                <Button type={"submit"}>Submit</Button>
                            </Col>
                        </Row>
                    </>


                }
            </Form>
        </Container>
    );
}

/*
String username;
	String fullName;
	String email;

	//Scheduling
	LocalDate booksOpenUntil; // First day of books closed
	@Builder.Default
	Integer maxTattooHours = 5;
	@Builder.Default
	Integer maxAppointmentLength = 5;
	Integer openBufferHours; // minimum possible hours between the booking and the appointment
	Integer buddyBufferMinutes; // additional time for in between buddies

	@Builder.Default
	Integer minutesBetweenAvailableTimes = 45; // Number of minutes to leave between available times for scheduling

	//Tattoos
	@Getter(onMethod_ = @DynamoDbConvertedBy(WeeklyScheduleConverter.class))
	Map<DayOfWeek, LocalTimeRange> baseWeeklySchedule; // Can be overridden in another table
	@Getter(onMethod_ = @DynamoDbConvertedBy(TattooSettingsMapConverter.class))
	Map<TattooType, TattooSettings> tattooSettingsMap;
	@Getter(onMethod_ = @DynamoDbConvertedBy(DurationMapsConverter.class))
	Map<TattooStyle, NavigableMap<Integer, Integer>> durationMaps;
 */