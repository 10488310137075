import Container from "react-bootstrap/Container";
import Calendar from "react-calendar";
import {Col, Row, Stack} from "react-bootstrap";
import React, {useCallback, useState} from "react";
import {lightFormat} from "date-fns";
import Button from "react-bootstrap/Button";

const getDateTimeMap = (dateTimeObj) => {
    //convert object to map
    let convertedMap = new Map(Object.entries(dateTimeObj));
    // filter out any dates without times
    return new Map([...convertedMap].filter(([k, v]) => v.length > 0));
}

export default function TimePickerV2({
                                         closed,
                                         dateTimeObj,
                                         timeSelectedCallback,
                                         calenderTitle = "Select Date",
                                         timeTitle = "Select Time"
                                     }) {

    //filter any empty dates that might have been returned
    const dateTimeMap = getDateTimeMap(dateTimeObj);
    const earliestDate = [...dateTimeMap.keys()].sort()[0];

    const [selectedDate, setSelectedDate] = useState(earliestDate);
    const [selectedTime, setSelectedTime] = useState("");

    // CREATE CALLBACKS
    const tileDisabled = useCallback(({date, view}) => {
        let dateString = date.toISOString().split('T')[0]
        if (view === 'month') {
            return ![...dateTimeMap.keys()].includes(dateString);
        }
    }, [dateTimeMap]);

    const tileClassName = useCallback(({date, view}) => {
        let dateString = date.toISOString().split('T')[0];
        if (view === 'month' && [...dateTimeMap.keys()].includes(dateString)) {
            return "date-has-times";
        }
    }, [dateTimeMap]);

    const handleDateClick = (value) => {
        let valueString = lightFormat(value, "yyyy-MM-dd");
        if (selectedDate !== valueString) {
            setSelectedDate(valueString);
            setSelectedTime("");
            timeSelectedCallback("");
        }
    }

    const handleTimeClick = (event) => {
        setSelectedTime(event.target.value);
        timeSelectedCallback(event.target.value);
    }

    return (

        <Container
            className="bg-success d-flex flex-wrap text-light mb-3 rounded-3 p-2">
            <Stack className={""}>

                <div className={"h4 mx-auto mb-3"}>
                    {calenderTitle}
                </div>
                <div className="mx-auto mb-3">
                    <Calendar
                        defaultValue={new Date(earliestDate + "T00:00:00")}
                        onClickDay={handleDateClick}
                        minDate={new Date(earliestDate + "T00:00:00")}
                        maxDate={new Date(closed)}
                        minDetail={"month"}
                        tileDisabled={tileDisabled}
                        tileClassName={tileClassName}
                        next2Label={null}
                        prev2Label={null}
                    />
                </div>
            </Stack>

            <Stack className={""}>

                <p className={"h4 mx-auto"}>{timeTitle}</p>
                <p className="lh-sm small">All times are displayed in Mountain Time (MT)</p>

                <Container
                    className={"d-grid mb-3 gap-2 justify-content-center"}
                >
                    {dateTimeMap.has(selectedDate) && dateTimeMap.get(selectedDate).map((uiDateTime, index) => (
                        <Row key={uiDateTime.value}>
                            <Col>
                                <Button
                                    type="button"
                                    name={uiDateTime.value}
                                    className={"time-button btn-primary text-light px-3"}
                                    value={uiDateTime.value}
                                    onClick={(event) => {
                                        handleTimeClick(event);
                                    }}
                                    active={selectedTime === uiDateTime.value}
                                >{uiDateTime.displayStr}</Button>
                            </Col>
                        </Row>
                    ))}
                </Container>
            </Stack>
        </Container>
    );
}