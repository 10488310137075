

class Artist {
    #name;
    #title;
    #instagram;
    #bio;
    #profilePic;

    constructor(name, title, instagram, bio, profilePic) {
        this.#name = name;
        this.#title = title;
        this.#instagram = instagram;
        this.#bio = bio;
        this.#profilePic = profilePic;
    }

    get name() {
        return this.#name;
    }

    get title() {
        return this.#title;
    }

    get instagram() {
        return this.#instagram;
    }

    get bio() {
        return this.#bio;
    }

    get profilePic() {
        return this.#profilePic;
    }
}

export default Artist;