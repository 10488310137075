import Container from "react-bootstrap/Container";
import {useAuthContext} from "../auth/AuthContext";
import {useEffect, useState} from "react";
import {NavLink, Outlet, useNavigation} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import LeafSpinner from "../util/LeafSpinner";
import Form from "react-bootstrap/Form";
import lightForest from "../../assets/images/fun/light_forest.jpg"
import darkForest from "../../assets/images/fun/dark_forest.jpg"

export const adminLoader = (getAuth) => async ({params, request}) => {
    // try {
    //     let auth = await getAuth();
    // } catch (error) {
    //     console.log(error)
    //     if (error === "NOT VALID") {
    //         return redirect("/login")
    //     } else {
    //         throw error
    //     }
    // }
    return null;
}

const navLinks = [
    {
        title: "Home",
        to: ".",
        end: true,
    },
    {
        title: "Settings",
        to: "settings"
    },
    {
        title: "Schedule",
        to: "schedule"
    },
    {
        title: "Flash",
        to: "flash"
    }
]

const getDarkMode = () => {
    let stored = localStorage.getItem("darkMode");
    return stored ? JSON.parse(stored) : false;
}

export const ArtistHome = () => {
    const authContext = useAuthContext();
    const navigation = useNavigation();

    const [darkMode, setDarkMode] = useState(getDarkMode());

    useEffect(() => {
        localStorage.setItem('darkMode', JSON.stringify(darkMode));
    }, [darkMode]);

    const toggleDarkMode = () => {
        setDarkMode(prev => !prev);
    }

    return (

        <main className={`h-100 d-flex flex-column ${darkMode ? "bg-dark" : ""}`}>
            <Container fluid id={"admin-header-container"} className={"d-grid mb-3 py-2"}
                       style={{
                           minHeight: "250px",
                           height: "250px",
                           backgroundImage: `url(${darkMode ? darkForest : lightForest})`,
                           backgroundPosition: "center",
                           backgroundRepeat: "no-repeat",
                           backgroundSize: "cover",
                       }}
            >
                <Row>
                    <Col className={"d-flex align-items-start justify-content-center"}
                         xs={{order: 'first', span: 6}}
                         md={{order: 'first', span: 3}}>
                        <div className={"bg-dark "}>
                            <span className={"text-light"}>Current User: {authContext.userData.username}</span>
                        </div>
                    </Col>
                    <Col className={"d-flex align-items-end justify-content-center"}
                         xs={{order: 'last', span: 12}}
                         md={{span: 6}}>
                        <Row className={`d-flex justify-content-center ${darkMode ? "bg-dark text-light" : "bg-light text-black"}`}>
                            {navLinks.map((link, index) => (
                                <Col key={"link" + index} xs={"auto"} className={""}>
                                    <NavLink
                                        className={({isActive, isPending, isTransitioning}) =>
                                            [
                                                isPending ? "pending " : "",
                                                isActive ? "bg-info fw-bold text-black" : "",
                                                isTransitioning ? "transitioning " : "",
                                                `${darkMode ? "text-light" : "text-black"} text-decoration-none fs-5`
                                            ].join(" ")
                                        }
                                        to={link.to}
                                        end={link.end}>
                                        <span className={"mx-2"}>{link.title}</span>
                                    </NavLink>

                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <Col className={"d-flex align-items-start justify-content-center text-light"}
                         xs={{order: 2, span: 6}}
                         md={{order: 'last', span: 3}}>
                        <div className={"bg-dark "}>
                            <Form.Switch
                                id={"dm-switch"}
                                checked={darkMode}
                                label={"Dark Mode"}
                                onChange={toggleDarkMode}
                            />
                        </div>
                    </Col>
                </Row>

            </Container>
            <Container id={"admin-content"} fluid
                       className={` d-flex flex-column flex-grow-1 my-3 ${darkMode ? "bg-dark" : "bg-light"}`}>

                {navigation.state === "loading" && <div className={"loading-overlay"}><LeafSpinner/></div>}
                <Outlet context={{darkMode: darkMode}}/>

            </Container>

        </main>
    )
        ;
}