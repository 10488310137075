import React, {FormControl, FormGroup, FormLabel} from "react-bootstrap";
import RequiredText from "../../formparts/RequiredText";
import Container from "react-bootstrap/Container";
import {useFormContext} from "react-hook-form";
import ImageDrop from "../../../util/ImageDrop";


export default function TouchupTattoo({fullId, getError, isProcessing}) {

    const { register } = useFormContext();

    return (
        <Container>
            <FormGroup className="mb-3" controlId="description">
                <FormLabel>Description <RequiredText
                    isMissing={!!getError("description")}/></FormLabel>
                <FormControl as="textarea" rows={5}
                             placeholder={"Briefly describe what needs touching up."}
                             {...register(`${fullId}.description`, {
                                 required: true
                             })}
                             isInvalid={!!getError("description")}
                />
            </FormGroup>
            <ImageDrop
                id={fullId}
                label={"Recent Picture of the Tattoo"}
                required={true}
                isProcessing={isProcessing}
                inputName="files"
                errors={getError("files")}
            />

        </Container>
    );
}