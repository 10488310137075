import {AuthenticationDetails, CognitoUser, CognitoUserPool} from "amazon-cognito-identity-js";
import LoginConfig from "./LoginConfig";


const userPool = new CognitoUserPool(LoginConfig.poolData);

export const RouteAuth = {
    getAuth: async () => {
        let currentUser = userPool.getCurrentUser();
        return new Promise(function (resolve, reject) {
            if (!currentUser) {
                reject("NOT VALID");
            }
            currentUser.getSession(function (err, session) {
                if (err) {
                    reject(err)
                } else {
                    resolve({
                        idToken: session.getIdToken(),
                        username: currentUser.getUsername(),
                    })
                }
            })
        })
    },
 }