import {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import React, {Collapse, ListGroup, ListGroupItem} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {ReactComponent as ExpandIcon} from "../../../assets/images/icons/chevron-up-svgrepo-com.svg";
import {ReactComponent as CollapseIcon} from "../../../assets/images/icons/chevron-down-svgrepo-com.svg";
import { Outlet, useLoaderData} from "react-router-dom";
import {doJsonGET} from "../../util/FetchUtils";

export const bookingLoader = async ({params, request}) => {

    const response = await doJsonGET(`v2/appointments/${params.appointmentId}`);
    if (response.ok) {
        return await response.json();
    } else {
        throw response
    }
}

export default function BookingWrapper() {

    useEffect(() => {
        let scriptTag = document.getElementById("clover");
        if (!scriptTag) {
            scriptTag = document.createElement("script");
            scriptTag.src = "https://checkout.clover.com/sdk.js"; // Prod
            scriptTag.async = true;
            scriptTag.id = "clover";
            scriptTag.type = "text/javascript";
            document.getElementById("root").appendChild(scriptTag);
            console.log("script tag created");
        }
    }, []);

    const loaderData = useLoaderData();
    console.log(loaderData);

    return (
        <>
            <SchedulingDetails loaderData={loaderData} />
            <Outlet context={{deposit: loaderData.deposit}} />
        </>
    );
}

export function SchedulingDetails({loaderData}) {
    const [detailsOpen, setDetailsOpen] = useState(true);


    return (
        <Container className="bg-success mb-3 p-3 rounded-3 d-flex flex-column column-gap-1 row-gap-1">
            <Button
                className="bg-light text-black mb-0 d-flex align-items-center"
                onClick={() => setDetailsOpen(!detailsOpen)}
                aria-controls="notes-collapse-text"
                aria-expanded={detailsOpen}
            >
                        <span className="flex-grow-1 h5">
                            Appointment Details
                        </span>
                {detailsOpen
                    ? <CollapseIcon fill="black" className="mx-2" style={{height: "20px", width: "20px", minWidth: "20px"}}/>
                    : <ExpandIcon fill="black" className="mx-2" style={{height: "20px", width: "20px", minWidth: "20px"}}/>
                }

            </Button>
            <Collapse in={detailsOpen}>
                <ListGroup>
                    <ListGroupItem><strong>Artist: </strong>{loaderData.artistFullName}</ListGroupItem>
                    <ListGroupItem><strong>Estimated Length: </strong>{loaderData.tattooMinutes + " minutes"}</ListGroupItem>
                    <ListGroupItem><strong>Estimated Cost: </strong> ${loaderData.cost}</ListGroupItem>
                    <ListGroupItem><strong>Reserved Time: </strong>{loaderData.reserved && loaderData.start && <span>{loaderData.start}</span>}</ListGroupItem>
                </ListGroup>
            </Collapse>
        </Container>

    );


}