const accordionData =
    [
        {
            id: 1, title: "Making an appointment", children:
                [
                    {
                        id: "1A", title: "Do you offer consultations?", children:
                            [
                                { id: "", title: "Our artists schedules are quite full, and we do not have much time to fit consultations into our schedules, unfortunately. In order for your artists to have all of the information they need, we replaced consultations with our very detailed inquiry form which is built into the booking process. If our artists have any questions about your design, they will reach out to you via email or text. Please make sure you are very thorough with your inquiry forms to make sure we have everything we need." }
                            ]
                    },
                    {
                        id: "1B", title: "How much will my tattoo cost?", children:
                            [
                                { id: "", title: "Our artists have varied rates between $100-200 an hour, so there is no one answer to this question. If you decide to book, you will receive a quote based on the artist you select and the answers you input in your inquiry form before you schedule your appointment. We start charging at the time of your appointment so please be on time." }
                            ]
                    },
                    {
                        id: "1C", title: "Do you take deposits?", children:
                            [
                                { id: "", title: "Yes, we take a non-refundable deposit for your appointment, due at the time of booking. This deposit will go toward the cost of your appointment. The deposit amount is different based on the artist you book with, and is 1/2 the cost of our minimum rate. Calista requires a $50 deposit, and Erika requires $100." }
                            ]
                    },
                    {
                        id: "1D", title: "How do I get a touch up?", children:
                            [
                                { id: "", title: "Touch ups are free and are needed on occasion. You can book a touch up on our booking page. Please make sure you wait at least six weeks from your original appointment before booking a touch up." }
                            ]
                    },
                    {
                        id: "1E", title: "Do you tattoo anyone under the age of 18?", children:
                            [
                                { id: "", title: "No, we do not make any exceptions for underage clients, and we require that you sign a waiver that states that you are not underage. We also include a photo of your ID in our consent form. Please do not book an appointment if you are under 18." }
                            ]
                    },
                    {
                        id: "1F", title: "Do you offer coverups?", children:
                            [
                                { id: "", title: "Neither of our artists offer coverups at this time. Fine line tattoos, which is what we specialize in at Botanical Ink, don’t lend well to covering old tattoos up, especially when the lines are thicker." }
                            ]
                    },
                    {
                        id: "1G", title: "Do you offer piercings?", children:
                            [
                                { id: "", title: "We do not offer piercings as a service at this time, only tattoos." }
                            ]
                    },
                    {
                        id: "1H", title: "Do you take walk-ins?", children:
                            [
                                { id: "", title: "No, we are an appointment only shop." }
                            ]
                    },
                    {
                        id: "1I", title: "Will my artist send my design ahead of time?", children:
                            [
                                { id: "", title: "No. In order to allow our clients plenty of time make changes to their tattoo idea, which is quite common, our artists typically create your design quite close to the day of your tattoo. We also book in plenty of extra time to adjust designs on the day of your appointment if needed." }
                            ]
                    },
                    {
                        id: "1J", title: "Is it okay to get a tattoo if I'm sick?", children:
                            [
                                { id: "", title: "Getting a tattoo when your immune system isn't 100% isn't a good idea. You need your strength and white blood cells to help your new tattoo heal, and your body will have a difficult time if it's already fighting a virus. Not to mention, your artist may catch your illness as you will be in close quarters. It's best to reschedule for when you're feeling better." }
                            ]
                    },
                    {
                        id: "1K", title: "I have a beauty mark or a pimple where I want my tattoo, is that okay?", children:
                            [
                                { id: "", title: "We generally will not tattoo over freckles, or moles in case you ever need them examined by a doctor. Adding ink to them can prevent a doctor from seeing what's going on with your skin. If you want the tattoo in that spot still, we are happy to put the design there and skip over the beauty mark. If you have a pimple, we can tattoo over small blemishes, but keep in mind that you may end up needing a touch up because it's possible it may not heal perfectly." }
                            ]
                    },
                    {
                        id: "1L", title: "Can I get a tattoo if I am pregnant?", children:
                            [
                                { id: "", title: "No please reschedule until after you've had the baby. A tattoo can be stressful on the body for a pregnant mother, and its best to wait." }
                            ]
                    },
                    {
                        id: "1M", title: "Can I bring in a design by another artist?", children:
                            [
                                { id: "", title: "We have no problem tattooing pre-designed tattoos as long as you have the artists permission, or you've paid for a tattoo ticket from their artists page if they sell them." }
                            ]
                    },
                    {
                        id: "1N", title: "Will you fix a tattoo for me if I got it from a different shop?", children:
                            [
                                { id: "", title: "You will need to reach out to your artist and send a photo of the tattoo to confirm whether this will be possible or not. We do not specialize in all styles, so sometimes the answer will be no." }
                            ]
                    },
                    {
                        id: "1O", title: "Are there any medical conditions that would prevent me from getting tattooed?", children:
                            [
                                { id: "", title: "Yes, we have to consider health conditions when tattooing our clients. We don't tattoo anyone with severe acne, currently healing scars, or eczema in the location they're getting their tattoo, anyone with heart conditions, people undergoing treatment for cancer, anyone on blood thinners, or people with hemophilia. If you have questions about a medical condition not listed here, please speak with your doctor before booking an appointment." }
                            ]
                    }
                ]
        },

        {
            id: 2, title: "Preparing for your appointment", children:
                [
                    {
                        id: "2A", title: "Is your shop wheelchair accessible?", children:
                            [
                                { id: "", title: "Unfortunately, no. There is a set of stairs to get down to the shop and there is no elevator. Our building is over 100 years old and was not built with accommodations. However, if you need assistance we will help you in any way we can." }
                            ]
                    },
                    {
                        id: "2B", title: "What should I do to prepare for my appopintment?", children:
                            [
                                { id: "", title: "Drink plenty of water, eat a meal before your appointment, try to be well rested, moisturize the area for at least the week before your appointment, and avoid painkillers, alcohol, blood thinners, sun exposure, and coffee on the day of." }
                            ]
                    },
                    {
                        id: "2C", title: "When should I show up for my appointment?", children:
                            [
                                { id: "", title: "Please show up to your appointment 10 minutes early to make sure you can find parking. Please do not come into the shop more than ten minutes before your appointment. We have limited space, and we want to make sure we can accommodate everyone during their allotted times." }
                            ]
                    },
                    {
                        id: "2D", title: "Can I use numbing cream?", children:
                            [
                                { id: "", title: "While we don't sell any numbing cream at our shop, we have no problem tattooing you if you've applied it at home. We recommend using numbing cream that is made specifically for tattoos, and reading instructions and applying it before your tattoo appointment. Saran Wrap can be useful in preventing it from drying out while it continues working on your way to your appointment." }
                            ]
                    },
                    {
                        id: "2E", title: "Can I take anything to help reduce the pain before my appointment?", children:
                            [
                                { id: "", title: "Some painkillers such as Advil and Ibuprofen can thin your blood, which will lead to similar issues that alcohol causes and can keep your tattoo from healing well. The best thing you can do for yourself is to get good rest, drink plenty of water, and eat a meal before your appointment." }
                            ]
                    },
                    {
                        id: "2F", title: "Can I bring moral support to my appointment?", children:
                            [
                                { id: "", title: "We have limited space, but we do allow you to bring one guest to your appointment." }
                            ]
                    },
                    {
                        id: "2G", title: "Can I bring my kids to my appointment?", children:
                            [
                                { id: "", title: "We don't recommend bringing kids to your appointment if you can avoid it. Tattoo studios are not a place that kids should be exploring in, and your artist will be unable to keep an eye on your little ones to make sure they are safe." }
                            ]
                    },
                    {
                        id: "2H", title: "Can I bring my service animal?", children:
                            [
                                { id: "", title: "If you have a medically necessary service animal, or a service animal in training, we will allow you do bring your animal into the shop." }
                            ]
                    },
                    {
                        id: "2I", title: "What should I bring to my appointment?", children:
                            [
                                { id: "", title: "A government form of ID, and if your appointment is longer, feel free to bring drinks, snacks, headphones, or a book with you." }
                            ]
                    },
                    {
                        id: "2J", title: "How can I reach my artist if I have an emergency the day of my appointment?", children:
                            [
                                { id: "", title: "Please email us at Contact@botanical.ink if you unexpectedly can't make it to your appointment. Please note that if your tattoo is within the 72 hour window, you will forfeit your non-refundable deposit." }
                            ]
                    },
                    {
                        id: "2K", title: "What is your cancellation policy?", children:
                            [
                                { id: "", title: "We ask that if you need to cancel your appointment, to please do so at least 72 hours before your appointment. If you need to cancel within the 72 hour window, please reach out to us at contact@botanical.ink" }
                            ]
                    },
                ]
        },
        {
            id: 3, title: "During your appointment", children:
                [
                    {
                        id: "3A", title: "I'm feeling lightheaded during my appointment, what do I do?", children:
                            [
                                { id: "", title: "Tell your artist asap! We will lay you back so you don't fall if you go unconscious, and we have drinks and snacks to try to help you feel better. The last thing we want is for you to keep it to yourself and possibly hurt yourself if you pass out. It's a fairly common experience, so make sure to speak up." }
                            ]
                    },
                    {
                        id: "3B", title: "Can I talk to my artist during my tattoo?", children:
                            [
                                {
                                    id: "", title: "While you are welcome to bring something to keep yourself occupied during your tattoo, our artists are very friendly and would love to get to know you! Chatting can also help distract you if you are struggling with any pain."
                                }
                            ]
                    },
                    {
                        id: "3C", title: "Is it okay to move the stencil once it's placed or make changes to the design during the appointment?", children:
                            [
                                {
                                    id: "", title: "Our artists are dedicated to making you feel comfortable in our shop. If you want to make and changes, please don't be afraid to speak up! We know a tattoo can be an intimidating experience, especially if its your first time, but it's on you forever and we want you to love it forever."
                                }
                            ]
                    }
                ]
        },
        {
            id: 4, title: "The aftercare process", children:
                [
                    {
                        id: "4A", title: "Is it normal for my tattoo to be red/itchy/flaky during the healing proccess?", children:
                            [
                                { id: "", title: "Yes, all of those things are perfectly normal during the healing process. If your tattoo is getting flaky at all, keep reapplying moisturizer until it's fully healed." }
                            ]
                    },
                    {
                        id: "4B", title: "I've left the shop and my saniderm has ink/liquid under the bandage, is my tattoo okay?", children:
                            [
                                {
                                    id: "", title: "Yes, this is totally normal. Depending on how much we tattooed your skin and how your body handles skin trauma, your skin may release a bit of ink and plasma into your bandage. This is perfectly okay, and can actually aid in the healing process."
                                }
                            ]
                    },
                    {
                        id: "4C", title: "What should I avoid after getting my new tattoo?", children:
                            [
                                {
                                    id: "", title: "We recommend avoiding working out, swimming, spending time in the sun, baths, wearing tight clothing, wearing sunscreen, and scratching your fresh tattoo for at least 2-3 weeks. Keep your sandierm on for the recommended duration by your artist, keep it clean by washing it once or twice a day, and moisturize in small amounts several times a day."
                                }
                            ]
                    },
                ]
        },
        {
            id: 5, title: "This is my first tattoo", children:
                [
                    {
                        id: "5A", title: "Are tattoos safe?", children:
                            [
                                { id: "", title: "As long as you go to a reputable artist that takes all sanitary precautions seriously, getting a tattoo is perfectly safe. Make sure you're honest with your artist about any medical conditions, and do your best to follow aftercare instructions to make sure your tattoo heals well."}
                            ]
                    },
                    {
                        id: "5B", title: "I'm getting my first tattoo is there anything I should consider first?", children:
                            [
                                {
                                    id: "", title: "Make sure you have a firm idea of what you want before making your appointment for a custom piece (What is your budget? Sizing?). Do your research. Make sure you choose an artist that suits the style you're looking for, such as: fine line vs thick, color vs black and white, realism vs new school."
                                }
                            ]
                    },
                    {
                        id: "5C", title: "Do tattoos hurt?", children:
                            [
                                {
                                    id: "", title: "When it comes to tattoos, pain is relative. Everyone experiences tattoos differently, but that doesn't mean it's painless. We describe it as irritating but generally tolerable, and we usually relate the feeling to scratching a sunburn, or getting a cat scratch."
                                }
                            ]
                    },
                    {
                        id: "5D", title: "Is your ink safe?", children:
                            [
                                {
                                    id: "", title: "We use Dynamic, Solid, and Eternal Inks in our shop. Each of these brands are widely used in the tattoo community and are well known as safe inks. Please keep in mind that some people have allergies to certain colors of inks, most commonly red hues. If you have a tattoo booked and its your first time getting red ink, make sure you're starting small."
                                }
                            ]
                    }
                ]
        }
    ];
export default accordionData