import {useFormContext} from "react-hook-form";
import React, {FormControl, FormGroup, FormLabel} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import "../../../assets/styles/Forms.css";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import Form from "react-bootstrap/Form";
import {TextInput} from "../formparts/TextInput";
import RequiredText from "../formparts/RequiredText";
import {useState} from "react";


export default function ClientDetailsV2({clientId = 0}) {
    const {
        register,
        control,
        formState: {
            errors
        }
    } = useFormContext();

    const [fullId, setFullId] = useState(`clients.${clientId}`);

    const getError = (name) => {
        let path = `${fullId}.${name}`;
        return path.split('.').reduce((acc, part) => acc && acc[part], errors);
    }

    return (
        <>
            <Container className={"d-flex flex-row column-gap-3"}>
                <input type="hidden" {...register(`${fullId}.id`)} defaultValue={clientId}/>
                <TextInput
                    classN="mb-3 flex-grow-1"
                    parentName={fullId}
                    inputName="firstName"
                    label="First Name"
                    placeholder="Legal First Name"
                    required={true}
                    hasErrors={!!getError("firstName")}
                    register={register}
                />
                <TextInput
                    classN="mb-3 flex-grow-1"
                    parentName={fullId}
                    inputName="lastName"
                    label="Last Name"
                    placeholder="Legal Last Name"
                    required={true}
                    hasErrors={!!getError("lastName")}
                    register={register}
                />
            </Container>
            <Container className={"d-flex flex-row column-gap-3"}>
                <TextInput
                    classN="mb-3 flex-grow-1"
                    parentName={fullId}
                    inputName="preferredName"
                    label="Preferred Name"
                    placeholder="What do we call you?"
                    required={false}
                    hasErrors={!!getError("preferredName")}
                    register={register}
                />
                <TextInput
                    classN="mb-3 flex-grow-1"
                    parentName={fullId}
                    inputName="pronouns"
                    label="Pronouns"
                    placeholder="she/her, they/them, he/him"
                    required={false}
                    hasErrors={!!getError("pronouns")}
                    register={register}
                />
            </Container>
            <Container className={"d-flex flex-row column-gap-3"}>
                <TextInput
                    classN="mb-3 flex-grow-1"
                    parentName={fullId}
                    inputName="email"
                    label="Email"
                    placeholder="you@example.com"
                    required={true}
                    hasErrors={!!getError("email")}
                    register={register}
                />
                <FormGroup className="d-flex flex-column justify-content-end mb-3 flex-grow-1" controlId="phone">
                    <FormLabel>Phone <RequiredText
                        isMissing={!!getError("phone")}/></FormLabel>
                    <PhoneInput
                        name={`${fullId}.phone`}
                        inputComponent={FormControl}
                        placeholder="(208)-123-4567"
                        country="US"
                        control={control}
                        rules={{required: true}}
                        isInvalid={!!getError("phone")}
                    />
                </FormGroup>
            </Container>
            <Container>
                <TextInput
                    classN="mb-3 flex-grow-1"
                    parentName={fullId}
                    inputName="allergies"
                    label="Allergies we should be aware of?"
                    placeholder="latex, etc"
                    required={false}
                    hasErrors={!!getError("allergies")}
                    register={register}
                />
                <TextInput
                    classN="mb-3 flex-grow-1"
                    parentName={fullId}
                    inputName="accommodations"
                    label="Additional accommodation requirements?"
                    placeholder="SA triggers, etc"
                    required={false}
                    hasErrors={!!getError("accommodations")}
                    register={register}
                />
            </Container>
            <Container>
                <FormGroup className="mb-3" key="firstTattooRadio">
                    <div>Is this your first tattoo? <RequiredText
                        isMissing={!!getError("firstTattoo")}/></div>
                    <Form.Check
                        type="radio"
                        id="firstTattooYes"
                        label="Yes"
                        value="yes"
                        {...register(`${fullId}.firstTattoo`, {
                            required: true
                        })}
                    />
                    <Form.Check
                        type="radio"
                        id="firstTattooNo"
                        label="No"
                        value="no"
                        {...register(`${fullId}.firstTattoo`, {
                            required: true
                        })}
                    />
                </FormGroup>
            </Container>
        </>
    );

}