import Button from "react-bootstrap/Button";
import React, {Collapse, ListGroup, ListGroupItem} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {useState} from "react";
import {ReactComponent as ExpandIcon} from "../../../assets/images/icons/chevron-up-svgrepo-com.svg";
import {ReactComponent as CollapseIcon} from "../../../assets/images/icons/chevron-down-svgrepo-com.svg";

export const ImportantNotes = () => {
    const [notesOpen, setNotesOpen] = useState(true);


    return (
        <Container className="bg-success mb-3 p-3 rounded-3 d-flex flex-column column-gap-1 row-gap-1">
            <Button
                className="bg-light align-items-center text-black mb-0 d-flex"
                onClick={() => setNotesOpen(!notesOpen)}
                aria-controls="notes-collapse-text"
                aria-expanded={notesOpen}
            >
                        <span className="flex-grow-1 h5">

                            Thank you for choosing us! Before you begin, please take a moment to read
                                through these important notes:
                        </span>
                {notesOpen
                    ? <CollapseIcon fill="black" className="mx-2" style={{height: "20px", width: "20px", minWidth: "20px"}}/>
                    : <ExpandIcon fill="black" className="mx-2" style={{height: "20px", width: "20px", minWidth: "20px"}}/>
                }

            </Button>
            <Collapse in={notesOpen}>
                <ListGroup>
                    <ListGroupItem>
                        <strong>Non-Refundable Deposit:</strong> Every tattoo appointment will require a
                        non-refundable deposit at the time of booking.
                    </ListGroupItem>
                    <ListGroupItem>
                        <strong>Deposit Deduction:</strong> The deposit amount will be deducted from the
                        total
                        cost of your tattoo at the end of your appointment.
                    </ListGroupItem>
                    <ListGroupItem>
                        <strong>Rescheduling Flexibilty:</strong> Life happens! If you need to reschedule
                        your
                        appointment, your deposit will seamlessly transfer to your new appointment date.
                    </ListGroupItem>
                    <ListGroupItem>
                        <strong>72-Hour Notice:</strong> Appointments may not be rescheduled within 72 hours
                        of
                        the scheduled time.
                    </ListGroupItem>
                    <ListGroupItem>
                        <strong>Deposit Expiration:</strong> Unfortunately, we cannot hold on to your
                        deposit indefinitely. If your appointment does not occur within one year of booking,
                        the deposit will be considered forfeit.
                    </ListGroupItem>
                    <ListGroupItem>
                        <strong>Touch-ups:</strong> In order to ensure you love your tattoo for years to
                        come,
                        our
                        artists offer periodic touch-ups free of charge. Touch-up appointments do not
                        require a
                        deposit.
                    </ListGroupItem>
                </ListGroup>
            </Collapse>
        </Container>
    );
}