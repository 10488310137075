import {useFormContext, useWatch} from "react-hook-form";
import Container from "react-bootstrap/Container";
import React, {FormGroup, FormLabel, FormSelect} from "react-bootstrap";
import RequiredText from "../formparts/RequiredText";
import {useEffect, useState} from "react";
import CustomTattoo from "./types/CustomTattoo";
import TouchupTattoo from "./types/TouchupTattoo";
import {FlashTattoo} from "./types/FlashTattoo";

export const doesCustoms = (settings) => {
    return settings && settings.tattooSettingsMap && settings.tattooSettingsMap["CUSTOM"];
}

export const hasFlash = (flashSets) => {
    return flashSets && flashSets.length > 0
}

export const getDefaultType = (artistSettings, flashSets) => {
    if (doesCustoms(artistSettings)) {
        return "custom"
    } else if (hasFlash(flashSets)) {
        return "flash";
    } else {
        return "touchup";
    }
}

export default function TattooDetailsV2({
                                            id = 0,
                                            clientId = 0,
                                            displayOrder = 2,
                                            artistSettings,
                                            flashSets,
                                            isProcessing,
                                        }) {
    const {
        register,
        setValue,
        control,
        formState: {
            errors,
        }
    } = useFormContext();

    const [fullId, setFullId] = useState(`clients.${clientId}.tattoos.${id}`);
    const [tattooType, setTattooType] = useState(getDefaultType(artistSettings, flashSets));

    useEffect(() => {
        setTattooType(getDefaultType(artistSettings, flashSets));
    }, [artistSettings, flashSets]);


    const watchFirstName = useWatch({
        control,
        name: `clients.${clientId}.firstName`,
        defaultValue: "",
    });

    const watchPrefName = useWatch({
        control,
        name: `clients.${clientId}.preferredName`,
        defaultValue: "",
    });

    const getError = (name) => {
        let path = `${fullId}.${name}`;
        return path.split('.').reduce((acc, part) => acc && acc[part], errors);
    }

    return (
        <Container className="bg-success d-flex flex-column justify-content-center text-light mb-3 rounded-3">
            <Container>
                <h1>
                    {((watchPrefName !== "" ? watchPrefName + "'s " : (watchFirstName !== "" ? watchFirstName + "'s " : "Your ") )
                        + "Tattoo" + (id === 0 ? "" : ` #${displayOrder}`))}
                </h1>
            </Container>
            <FormGroup className="mb-3 d-flex flex-column align-items-center w-75 mx-auto">
                <FormLabel className="mb-1">
                    Type of Tattoo <RequiredText isMissing={!!getError("type")}/>
                </FormLabel>
                <FormSelect
                    name="tattooType"
                    {...register(`${fullId}.type`, {
                        required: true
                    })}
                    value={tattooType}
                    onChange={e => {
                        setTattooType(e.target.value);
                        setValue(`${fullId}.type`, e.target.value);
                    }}
                >
                    {doesCustoms(artistSettings) && <option value="custom">Custom Tattoo</option>}
                    {hasFlash(flashSets) && <option value="flash">Pre-Drawn Flash</option>}
                    <option value="touchup">Touch Up</option>
                </FormSelect>
            </FormGroup>

            {tattooType === "custom" &&
                <CustomTattoo fullId={fullId} artistSettings={artistSettings} getError={getError}
                              isProcessing={isProcessing}/>
            }
            {tattooType === "touchup" &&
                <TouchupTattoo fullId={fullId} getError={getError}
                               isProcessing={isProcessing}/>
            }

            {tattooType === "flash" &&
                <FlashTattoo fullId={fullId} getError={getError} flashSets={flashSets}/>
            }
            <input type="hidden" {...register(`${fullId}.id`)} defaultValue={id}/>
        </Container>
    );
}