import Image from "react-bootstrap/Image";
import MonsteraLeaf from "../../assets/images/monstera-leaf.svg";
import "../../assets/styles/Loader.css";

export default function LeafSpinner() {
    return (
        <Image
            className={"loading-spinner"}
            src={MonsteraLeaf}
            alt="loading spinner..."
        />
    );
}