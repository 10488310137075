import Container from "react-bootstrap/Container";

import {useEffect, useRef, useState} from "react";
import React, {Col, FormGroup, FormLabel, Form, Row, Spinner} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {
    redirect,
    useOutletContext,
    useSubmit,
    json,
    useActionData,
    useNavigation,
    useNavigate
} from "react-router-dom";


export const depositAction = async ({params, request}) => {
    console.log("payment action hit");

    let jsonData = await request.json();
    let response = await fetch(`https://botanical.ink/api/v2/appointments/${params.appointmentId}/deposit`,
        {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jsonData),

        });
    if (response.ok) {
        return redirect(`/appointment/manage/${params.appointmentId}?action=created&actionStatus=success`);
    } else if (response.status === 422) {
        return redirect(`/booking/${params.appointmentId}/schedule?message=TIME_UNAVAILABLE`, {
            status: 422,
            statusText: "TIME_UNAVAILABLE",
        });
    } else if (response.status === 400) {
        console.log("Card submit error :(");
        return await response.json();
    } else {
        console.log("Some other error..");
        console.log(response);
        return json({error: "SERVER_ERROR"});
    }
}

const cloverStyles = {
    input: {
// Fixes for https://community.clover.com/questions/24714/issue-in-clover-hosted-iframe-application-running.html
        padding: '0px',
        height: "38px",
        margin: '0px',
        width: "100%",
        // padding: "0.375rem 0.75rem",
        fontSize: "1rem",
        fontWeight: "400",
        lineHeight: "1.5",
        backgroundClip: "padding-box",
        borderRadius: "0.375rem",
        transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out"
    },
    "input:focus": {border: "1px solid red"}
};

const cloverElements = [
    {key: "CARD_NAME", elementId: "card-name", label: "Cardholder Name"},
    {key: "CARD_NUMBER", elementId: "card-number", label: "Card Number"},
    {key: "CARD_DATE", elementId: "card-date", label: "Expires"},
    {key: "CARD_CVV", elementId: "card-cvv", label: "CVV"},
    {key: "CARD_POSTAL_CODE", elementId: "card-zip", label: "Postal Code"},
]

const stupid = {
    name: {key: "CARD_NAME", elementId: "card-name", label: "Cardholder Name"},
    number: {key: "CARD_NUMBER", elementId: "card-number", label: "Card Number"},
    date: {key: "CARD_DATE", elementId: "card-date", label: "Expires"},
    cvv: {key: "CARD_CVV", elementId: "card-cvv", label: "CVV"},
    zip: {key: "CARD_POSTAL_CODE", elementId: "card-zip", label: "Postal Code"},
}

export default function AppointmentDeposit() {
    const submit = useSubmit(); //required
    const navigate = useNavigate();
    const navigation = useNavigation();
    let actionData = useActionData();
    console.log("payment action data:", actionData);
    const cloverRef = useRef();
    const contextData = useOutletContext();

    const [submitting, setSubmitting] = useState(false); //required
    const [tokenSubmitErrors, setTokenSubmitErrors] = useState([]); // probably?
    const [cardToken, setCardToken] = useState(null); //required

    useEffect(() => {
        if (window.Clover && !cloverRef.current) {
            console.log("initializing clover");
            cloverRef.current = new window.Clover("e1ee6c43b4bd20ed059e6988c134eaac")
            let elements = cloverRef.current.elements();
            cloverElements.map(({key, elementId}) => {
                let elem = elements.create(key, cloverStyles);
                elem.mount("#" + elementId);
            });

            let footer = document.getElementsByClassName("clover-footer")[0];
            let formContainer = document.getElementById("clover-form-container");

            if (footer && formContainer) {
                formContainer.append(footer);
            }
        }

    }, []);

    const onSubmit = async (event) => {
        event.preventDefault();
        setSubmitting(true);
        console.log("Submitting payment");

        let theToken = cardToken;
        if (!theToken) {
            const result = await cloverRef.current.createToken()
                .catch((error) => {
                    console.log('Error creating token:', error);
                    setSubmitting(false);
                });
            console.log(result);
            if (result.errors) {
                console.log("token submit errors");
                setTokenSubmitErrors(Object.values(result.errors));
                setSubmitting(false);
                return;
            } else {
                theToken = result.token;
                setCardToken(theToken);
            }
        }
        setSubmitting(false);
        if (theToken) {
            submit({
                cardToken: theToken,
            }, {
                method: "post",
                encType: "application/json",
            });
        }
    }

    const handleBackButton = () => {
        navigate(-1);
    }

    return (
        <Form noValidate onSubmit={onSubmit}>
            <Container
                id="clover-form-container"
                className="d-grid row-gap-1 bg-success text-light mb-3 rounded-3 p-3"
            >
                <Row>
                    <Col className={"d-flex"}>
                        <h2 className="text-light mx-auto">Deposit</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>Deposit Amount: <strong>${contextData.deposit}</strong></p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup controlId={stupid.name.label}>
                            <FormLabel className="">{stupid.name.label}</FormLabel>
                            <div style={{height: "42px"}} id={stupid.name.elementId}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup controlId={stupid.number.label}>
                            <FormLabel className="">{stupid.number.label}</FormLabel>
                            <div style={{height: "42px"}} id={stupid.number.elementId}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} sm={4}>
                        <FormGroup controlId={stupid.date.label}>
                            <FormLabel className="">{stupid.date.label}</FormLabel>
                            <div style={{height: "42px"}} id={stupid.date.elementId}/>
                        </FormGroup>
                    </Col>
                    <Col xs={6} sm={4}>
                        <FormGroup controlId={stupid.cvv.label}>
                            <FormLabel className="">{stupid.cvv.label}</FormLabel>
                            <div style={{height: "42px"}} id={stupid.cvv.elementId}/>
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm={4}>
                        <FormGroup controlId={stupid.zip.label}>
                            <FormLabel className="">{stupid.zip.label}</FormLabel>
                            <div style={{height: "42px"}} id={stupid.zip.elementId}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {actionData && actionData.message &&
                            <p className="bg-light text-danger my-3">Error: {actionData.message}</p>
                        }
                    </Col>
                </Row>
                <Row className="my-3 justify-content-center">
                    <Col xs={4} lg={2}>
                        <Button type="button" onClick={handleBackButton}
                                className="w-100" style={{backgroundColor: "gray", color: "black"}}
                                disabled={submitting || navigation.state !== "idle"}>Back</Button>
                    </Col>
                    <Col xs={8} lg={6}>
                        <Button type="submit" className="w-100 btn-secondary"
                                disabled={submitting || navigation.state !== "idle"}
                        >
                            {(submitting)
                                ? <span><Spinner size="sm" role="status" aria-hidden="true" className="me-1"/>
                                                Submitting</span>
                                : <span>Submit</span>
                            }
                        </Button>
                    </Col>
                </Row>
                <p className="lh-sm small text-light">
                    Payment handled directly through Clover.
                    <strong> At no point does our system have access to your payment
                        information. </strong>
                    After your payment is processed, you will recieve an additional email from Clover with your
                    receipt.
                </p>
            </Container>
        </Form>
    );
}