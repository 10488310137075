
export default function logAxiosError(error) {
    if (error.response) {
        console.log("Problem with response");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
    } else if (error.request) {
        console.log("Problem with request");
        console.log(error.request);
    } else {
        console.log("Problem with YOU");
        console.log('Error', error.message);
    }
}