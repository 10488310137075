import {FormProvider, useForm} from "react-hook-form";
import ClientDetailsV2 from "./ClientDetailsV2";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, {Alert, Spinner} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {useState} from "react";
import ArtistSelect from "../formparts/ArtistSelect";
import {json, redirect, useActionData, useLoaderData, useNavigation, useSubmit} from "react-router-dom";
import Over18Checkbox from "../formparts/Over18Checkbox";
import TattooDetailsV2 from "./TattooDetailsV2";
import {ReactComponent as ErrorIcon} from "../../../assets/images/icons/caution_triangle_filled.svg"
import {ReactComponent as CloseIcon} from "../../../assets/images/icons/x-svgrepo-com.svg";
import {doJsonGET, doJsonPOST} from "../../util/FetchUtils";
import {ImportantNotes} from "./ImportantNotes";

const postPath = "v2/appointments";

export const createLoaderV2 = async ({request}) => {
    try {
        console.log("create loader")
        const response = await doJsonGET("book-now");
        if (response.ok) {
            let theJson = await response.json();
            return theJson;
        }
    } catch (error) {
        return json({
            error: '500',
            message: "An error occurred while making a request to the server"
        })
    }
}

export const createActionV2 = async ({params, request}) => {
    // POST and redirect to schedule page
    let jsonData = await request.json();

    try {
        const response = await doJsonPOST(postPath, jsonData);
        let responseData = await response.json();
        console.log(responseData);
        if (response.ok) {
            // redirect to next step
            return redirect(`/booking/${responseData.aid}/schedule`);
        } else if (response.status === 422) {
            // appointment validation issue, return error object
            return responseData;
        } else {
            return json({
                error: responseData.status,
                message: responseData.message
            })
        }
    } catch (error) {
        return json({
            error: '500',
            message: "An error occurred while making a request to the server"
        })
    }
}

export default function AppointmentCreateV2() {
    const methods = useForm();
    const [myFormState, setMyFormState] = useState({
        submitting: false,
        submitted: false, //means the user attempted to submit, not actual success
        hasProcessingFiles: false,
        serverErrors: null,
    });
    const {formState: {isSubmitted, isValid}} = methods;
    const loaderData = useLoaderData();
    const actionData = useActionData();

    if (actionData) console.log(actionData);

    const submit = useSubmit();
    const [selectedArtist, setSelectedArtist] = useState(null);
    const [artistSettings, setArtistSettings] = useState(null);
    const [flashSets, setFlashSets] = useState(null);
    const [hasBuddy, setHasBuddy] = useState(false);
    const navigation = useNavigation();

    const onSubmit = (data) => {
        setSubmitting(true);
        if (!data.artist) {
            data.artist = selectedArtist;
        }
        submit(data, {
            method: "post",
            encType: "application/json",
        });
    }

    const setProcessingFiles = (value) => setMyFormState(prevState => ({
        ...prevState,
        hasProcessingFiles: value
    }));

    const setSubmitting = (value) => setMyFormState(prevState => ({
        ...prevState,
        submitting: value,
        submitted: true,
    }));

    const toggleBuddy = () => {
        setHasBuddy(!hasBuddy);
    }

    const changeSelectedArtist = (artistName) => {
        setSelectedArtist(artistName);
        setArtistSettings(loaderData.settings ? loaderData.settings[artistName] : null);
        setFlashSets(loaderData.flash ? loaderData.flash[artistName] : null);
    }


    return (
        <FormProvider {...methods} >
            <ImportantNotes />
            <Form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                <Container className="bg-success text-light mb-3 rounded-3 d-flex flex-column column-gap-1 row-gap-1">
                    <div key="artistTitle" className={"h1 mx-auto my-2"}>
                        Artist Selection
                    </div>
                    <ArtistSelect setSelectedArtist={changeSelectedArtist}/>
                </Container>
                {selectedArtist &&
                    <>
                        <Container
                            className="bg-success d-flex flex-column text-light mb-3 rounded-3 p-2"
                        >
                            <Container className={""}><h1>About You</h1></Container>
                            <AppointmentDetails
                                clientId={0}
                                artistSettings={artistSettings}
                                isProcessing={setProcessingFiles}
                                artistName={selectedArtist}
                                flashSets={flashSets}
                            />
                            <Container className={"mb-3"}>

                                <Container className={"d-flex justify-content-center justify-content-lg-start"}>
                                    <Button
                                        className={"mb-3 flex-grow-1 flex-lg-grow-0"}
                                        type={"button"}
                                        onClick={toggleBuddy}
                                        disabled={hasBuddy}
                                        style={{minWidth: "300px"}}
                                    >
                                        + Add Another Person
                                    </Button>
                                </Container>
                                {hasBuddy &&
                                    <span className={"small"}>* Maximum of two people per appointment</span>
                                }
                            </Container>
                        </Container>

                        {/* buddy container */}
                        {hasBuddy &&
                            <Container
                                className="bg-success d-flex flex-column text-light mb-3 rounded-3 p-2"
                            >
                                <Container
                                    className="bg-success d-flex flex-column text-light rounded-3 p-2"
                                >
                                    <Button onClick={toggleBuddy}
                                            className="w-auto me-auto"
                                            aria-label="Remove Buddy">
                                        <CloseIcon fill="white" style={{height: "25px", width: "25px"}}/>
                                    </Button>

                                    <Container className={""}><h1>About Your Buddy</h1></Container>
                                    <AppointmentDetails
                                        clientId={1}
                                        artistSettings={artistSettings}
                                        isProcessing={setProcessingFiles}
                                        artistName={selectedArtist}
                                        flashSets={loaderData.flash[selectedArtist]}
                                    />
                                </Container>
                            </Container>
                        }

                        <Container className="bg-success text-light mb-3 rounded-3 p-3">

                            {isSubmitted && !isValid &&
                                <p>One or more inputs is invalid. Please correct them and try again</p>
                            }

                            {isSubmitted && actionData && actionData.message &&
                                <Alert variant={"danger"} className={"d-flex align-items-center"}>
                                    <ErrorIcon className={"fs-4 me-3"}
                                               style={{height: "1em", width: "1em", minWidth: "1em", color: "red"}}/>
                                    <span>{actionData.message}</span>
                                </Alert>
                            }

                            <Over18Checkbox hasBuddy={hasBuddy}/>
                            <Container className={"d-grid"}>
                                <Button variant="primary" type="submit" className="d-grid"
                                        disabled={(navigation.state !== "idle" || myFormState.hasProcessingFiles)}
                                >
                                    {(myFormState.hasProcessingFiles || navigation.state !== "idle")
                                        ?
                                        <span>
                                            <Spinner size="sm" role="status" aria-hidden="true" className="me-1"/>
                                            {myFormState.hasProcessingFiles
                                                ? <span>Uploading</span>
                                                : <span>Submitting</span>}
                                        </span>
                                        :
                                        <span>Next</span>
                                    }
                                </Button>
                            </Container>
                        </Container>
                    </>
                }
            </Form>
        </FormProvider>
    );

}

// couldn't come up with a better name for something to wrap ClientDetails and TattooDetails inside...
export function AppointmentDetails({
                                       clientId = 0,
                                       artistSettings,
                                       isProcessing,
                                       artistName,
                                       flashSets
                                   }) {

    const [additionalTattoos, setAdditionalTattoos] = useState([]);
    const [nextTattooId, setNextTattooId] = useState(1)
    const defaultTattooId = 0;

    const addTattoo = () => {
        setAdditionalTattoos(
            [
                ...additionalTattoos,
                {id: nextTattooId}
            ]
        )

        setNextTattooId(nextTattooId + 1);
    }

    const removeTattoo = (id) => {
        setAdditionalTattoos(
            additionalTattoos.filter(tattoo => tattoo.id !== id)
        );
    }
    let displayOrder = 2;
    return (
        <>
            <ClientDetailsV2 clientId={clientId}/>
            <Container>
                <TattooDetailsV2
                    id={defaultTattooId}
                    clientId={clientId}
                    artistSettings={artistSettings}
                    isProcessing={isProcessing}
                    artistName={artistName}
                    flashSets={flashSets}
                />
                {additionalTattoos.map((tattoo) => (
                    <div key={"div." + tattoo.id}>
                        <Button onClick={() => removeTattoo(tattoo.id)}
                                key={"closeButton." + tattoo.id}
                                className="w-auto me-auto"
                                aria-label="Remove Tattoo">
                            <CloseIcon fill="white" style={{height: "25px", width: "25px"}}/>
                        </Button>
                        <TattooDetailsV2
                            key={"tattoo." + tattoo.id}
                            id={tattoo.id}
                            clientId={clientId}
                            displayOrder={displayOrder++}
                            artistSettings={artistSettings}
                            isProcessing={isProcessing}
                            artistName={artistName}
                            flashSets={flashSets}
                        />
                    </div>
                ))

                }
                <Container className={"justify-content-center justify-content-lg-start d-flex"}>
                    <Button
                        className={"mb-3 flex-grow-1 flex-lg-grow-0"}
                        onClick={() => addTattoo()}
                        disabled={additionalTattoos.length === 5} //max 6 total tattoos
                        style={{minWidth: "300px"}}
                    >
                        + Add Another Tattoo
                    </Button>
                </Container>
            </Container>
        </>
    );
}