import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import React from "react-bootstrap";

export default function LogoutForm({username, action}) {
    return (
        <Container className="bg-success d-flex flex-column justify-content-center text-light mb-3 rounded-3 p-2">
            <h1>Current User: {username}</h1>
            <Button variant="primary" onClick={action} className="mx-auto mb-3  w-25">
                Logout
            </Button>
        </Container>
    );
}