import {useFormContext} from "react-hook-form";
import Container from "react-bootstrap/Container";
import React, {FormControl, FormGroup, FormLabel} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

export default function ResetForm({onSubmit}) {
    const {register} = useFormContext();

    return (
        <Form noValidate onSubmit={onSubmit} className="p-3 form-bg">
            <Container className="bg-success d-flex flex-column justify-content-center text-light mb-3 rounded-3 p-2">
                <p>This is your first time logging in. Please set a new password</p>
                <p>Must include one of each: </p>
                <p>Capital letter | Lowercase letter | Number | Symbol</p>
                <FormGroup className="mb-3" controlId="newPassword">
                    <FormLabel>New Password</FormLabel>
                    <FormControl type="password" name="newPassword" {...register("newPassword", {
                        // required: "new password required"
                    })}/>
                </FormGroup>
                <Button variant="primary" type={"submit"} className="mb-3 ">
                    Submit
                </Button>
            </Container>
        </Form>
    );

}