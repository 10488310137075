import React, {FormControl, FormGroup, FormLabel, FormSelect} from "react-bootstrap";
import RequiredText from "../../formparts/RequiredText";
import Container from "react-bootstrap/Container";
import {useFormContext, useWatch} from "react-hook-form";
import ImageDrop from "../../../util/ImageDrop";


export default function CustomTattoo({fullId, artistSettings, getError, isProcessing}) {
    const {
        register,
        control,
    } = useFormContext();

    const watchTattooStyle = useWatch({
        control,
        name: `${fullId}.style`,
        defaultValue: "line"
    });

    const watchLength = useWatch({
        control,
        name: `${fullId}.length`,
        defaultValue: 0
    });

    const watchWidth = useWatch({
        control,
        name: `${fullId}.width`,
        defaultValue: 0
    });

    const getMaxArea = () => {
        if (watchTattooStyle === "line") return artistSettings.maxAreaLine;
        if (watchTattooStyle === "shaded") return artistSettings.maxAreaShaded;
        if (watchTattooStyle === "color") return artistSettings.maxAreaColor;
    }

    return (
        <>
            <Container>
                <FormGroup className="mb-3 d-flex flex-column flex-grow-1">
                    <FormLabel className="mb-1">
                        Line-work, Shaded, or Color? <RequiredText
                        isMissing={!!getError("style")}/>
                    </FormLabel>
                    <FormSelect
                        name="style"
                        {...register(`${fullId}.style`, {
                            required: true,
                            validate: (value) => value !== "",
                            deps: [`${fullId}.area`]
                        })}
                        defaultValue=""
                    >
                        <option value="" disabled>Select Style</option>
                        <option
                            value="line">Line-work{artistSettings.maxAreaColor && artistSettings.maxAreaColor > 0 ? "" : " (black and grey)"}</option>
                        <option value="shaded">Shaded (black and grey)</option>
                        {artistSettings.maxAreaColor && artistSettings.maxAreaColor > 0 &&
                            <option value="color">Color</option>
                        }
                    </FormSelect>
                </FormGroup>
            </Container>
            <Container id="length-and-width" className={"d-flex flex-row column-gap-3"}>
                <FormGroup className="mb-3 flex-grow-1" controlId="length">
                    <FormLabel className="mb-1">Length (inches)<RequiredText
                        isMissing={!!getError("length")}/></FormLabel>
                    <FormControl type="number"
                                 placeholder="Length in inches"
                                 {...register(`${fullId}.length`, {
                                     shouldUnregister: true,
                                     required: true,
                                     min: {
                                         value: 1,
                                         message: "Minimum: 1"
                                     },
                                     max: {
                                         value: 20,
                                         message: "Maximum: 20"
                                     },
                                     deps: [`${fullId}.area`]
                                 })}
                                 isInvalid={!!getError("length")}
                    />
                </FormGroup>
                <FormGroup className="mb-3 flex-grow-1" controlId="width">
                    <FormLabel className="mb-1">Width (inches)<RequiredText
                        isMissing={!!getError("width")}/></FormLabel>
                    <FormControl type="number"
                                 placeholder="Width in inches"
                                 {...register(`${fullId}.width`, {
                                     shouldUnregister: true,
                                     required: true,
                                     min: {
                                         value: 1,
                                         message: "Minimum: 1"
                                     },
                                     max: {
                                         value: 20,
                                         message: "Maximum: 20"
                                     },
                                     deps: [`${fullId}.area`]
                                 })}
                                 isInvalid={!!getError("width")}
                    />
                </FormGroup>

            </Container>
            <Container>
                <FormGroup className="mb-3 flex-grow-1" controlId="area">
                    <FormLabel className="mb-1">Area <span style={{opacity: "0.7", fontSize: "small"}}>
                                (calculated)</span></FormLabel>
                    <FormControl type="number"
                                 value={watchLength * watchWidth}
                                 readOnly={true}
                                 aria-readonly={true}
                                 placeholder="Area"
                                 {...register(`${fullId}.area`, {
                                     shouldUnregister: true,
                                     validate: (value, formValues) => {
                                         return (watchLength * watchWidth) <= getMaxArea() ||
                                         `Maximum size for the selected artist and style is ${getMaxArea()} square inches`;
                                     }
                                 })}
                                 isInvalid={!!getError("area")}
                                 style={{
                                     backgroundColor: "lightgray",
                                 }}
                    />
                </FormGroup>
            </Container>
            <Container>
                {getError("area") &&
                    <p style={{color: "red"}}>{getError("area").message}</p>
                }
            </Container>
            <Container>
                <FormGroup className="mb-3" controlId="description">
                    <FormLabel>Description <RequiredText
                        isMissing={!!getError("description")}/></FormLabel>
                    <FormControl as="textarea" rows={5}
                                 placeholder={"Please provide a detailed description of the tattoo design."}
                                 {...register(`${fullId}.description`, {
                                     required: true
                                 })}
                                 isInvalid={!!getError("description")}
                    />
                </FormGroup>
                <FormGroup className="mb-3" controlId="placement">
                    <FormLabel>Tattoo Placement <RequiredText isMissing={!!getError("placement")}/></FormLabel>
                    <FormControl as="textarea" rows={5}
                                 placeholder="Please describe the exact placement for your new tattoo.
                                      Example: On the top of my left forearm, etc"
                                 {...register(`${fullId}.placement`, {
                                     required: true
                                 })}
                                 isInvalid={!!getError("placement")}
                    />
                </FormGroup>
                <ImageDrop
                    id={fullId}
                    label={"Reference Images"}
                    required={true}
                    isProcessing={isProcessing}
                    inputName="files"
                    errors={getError("files")}
                />
            </Container>
        </>
    );
}