import React, {FormGroup, FormLabel} from "react-bootstrap";
import RequiredText from "./RequiredText";
import Form from "react-bootstrap/Form";

export const TextInput = ({classN, parentName, inputName, label, placeholder, required, hasErrors, register}) => {
    return (
        <FormGroup className={classN} controlId={inputName}>
            <FormLabel>
                {label} {required &&
                <RequiredText isMissing={hasErrors}/>}
            </FormLabel>
            <Form.Control
                type={"text"}
                placeholder={placeholder}
                {...register(`${parentName}.${inputName}`,
                    required ? {required: true} : {}
                )}
                autoComplete={required ? "on" : undefined}
                isInvalid={hasErrors}
            />
        </FormGroup>
    );
}