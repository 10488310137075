import {json, redirect, useLoaderData, useNavigate, useSubmit} from "react-router-dom";
import Container from "react-bootstrap/Container";
import {FormCheck, FormControl, FormGroup, FormLabel, Stack} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useState} from "react";
import Form from "react-bootstrap/Form";
import {useAuthContext} from "../../auth/AuthContext";

export const editFlashSetLoader = (getAuth) => async ({params, request}) => {
    try {
        console.log("edit flash set loader")
        let auth = await getAuth();
        let response = await fetch(`https://botanical.ink/api/admin/flash/sets/${params.setId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.idToken.getJwtToken()}`,
            },
        });
        if (response.ok) {
            console.log("found flash set with that id")
            return await response.json();
            // } else if (response.status === 404) {
        } else {
            console.log("No flash set with that id")
            return redirect("/admin/flash");
        }
    } catch (error) {
        console.log(error)
        if (error === "NOT VALID") {
            return redirect("/login")
        } else {
            throw error
        }
    }
}

export const editFlashSetAction = (getAuth) => async ({params, request}) => {
    try {
        console.log("edit flash set action")
        let auth = await getAuth();
        const data = await request.json();
        let response = await fetch(`https://botanical.ink/api/admin/flash/sets/${params.setId}`, {
            method: "post",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.idToken.getJwtToken()}`,
            }
        });

        if (response.ok) {
            console.log("flash set post success")
            return redirect("/admin/flash");
        } else {
            console.log("flash set post not ok")
            return response;
        }
    } catch (error) {
        if (error === "NOT VALID") {
            // return redirect("/login")
            return json({
                error: 401,
                message: "UNAUTHORIZED"
            });
        } else {
            throw error
        }
    }
}

const uploadImageFile = async (idToken, file, setID) => {
    const params = new URLSearchParams({
        file: file.name
    });

    try {
        const urlResponse = await fetch(`https://botanical.ink/api/admin/flash/sets/${setID}/putobject-url?${params}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken.getJwtToken()}`,
            }
        });
        const {objectKey, url} = await urlResponse.json();

        console.log(objectKey);

        const config = {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };
        //upload the file
        await fetch(url, config);

        return {
            fileKey: objectKey,
            error: null,
        };

    } catch (error) {
        console.log(error)
        return {
            fileKey: null,
            error: error,
        };
    }
}

const createPiecesFromData = (loaderData) => {
    let pieces = loaderData.pieces;
    if (!pieces) {
        return []
    }

    return pieces.map((piece, index) => ({
        artist: piece.artist,
        id: piece.id,
        name: piece.name,
        setID: piece.setID,
        price: piece.price,
        deposit: piece.deposit,
        multiTattooDeal: loaderData.set.multiTattooDeal,
        multiTattooPrice: loaderData.set.multiTattooPrice,
        durationMinutes: piece.durationMinutes,
        limited: piece.limited,
        numAllowed: piece.numAllowed,
        numTaken: piece.numTaken,
        fileKey: piece.fileKey,
        displayOrder: piece.displayOrder,
    }));
}

const createSetFromData = (set) => {
    return {
        artist: set.artist,
        id: set.id,
        name: set.name ?? "",
        setDescription: set.setDescription ?? "",
        startDate: set.startDate ?? "",
        endDate: set.endDate ?? "",
        fileKey: set.fileKey ?? "",
        allSamePrice: set.allSamePrice ?? false,
        price: set.price ?? 0,
        deposit: set.deposit ?? 0,
        dealText: set.dealText ?? "",
        multiTattooDeal: set.multiTattooDeal ?? false,
        multiTattooPrice: set.multiTattooPrice ?? 0,
    }
}

export const EditFlashSet = () => {
    const navigate = useNavigate();

    const auth = useAuthContext();

    const submit = useSubmit();
    const loaderData = useLoaderData();
    const [set, setSet] = useState(createSetFromData(loaderData.set))
    const [pieces, setPieces] = useState(createPiecesFromData(loaderData));
    const cancelEdit = () => {
        navigate("/admin/flash");
    }

    const handleInputChange = (index, field, value) => {
        setPieces((prev) => {
            const updatedForms = [...prev];
            updatedForms[index][field] = value;
            return updatedForms;
        });
    };

    const handleImageUpload = async (index, file) => {
        let idToken = await auth.getIdToken();
        // handleInputChange(index, "uploading", true);

        let {error, fileKey} = await uploadImageFile(idToken, file, set.id);

        setPieces((prev) => {
            const updatedForms = [...prev];
            updatedForms[index].error = error;
            updatedForms[index].fileKey = fileKey;
            return updatedForms;
        });

        // handleInputChange(index, "uploading", true);
    }

    const handleSetChange = (field, value) => {
        setSet((prev) => ({
            ...prev,
            [field]: value,
        }));
    }

    const setAllPieceValues = (field, value) => {
        setPieces(prevPieces =>
            prevPieces.map(piece => ({
                ...piece,
                [field]: value
            }))
        );
    }

    const deletePieceAtIndex = (index) => {
        setPieces(prevPieces =>
            prevPieces.filter((_, i) => i !== index)
        );
    }

    const addPiece = () => {
        setPieces((prev) => [...prev, {
            artist: set.artist,
            id: "",
            name: "",
            setID: set.id,
            price: set.price,
            deposit: set.deposit,
            multiTattooDeal: set.multiTattooDeal,
            multiTattooPrice: set.multiTattooPrice,
            durationMinutes: 0,
            limited: false,
            numAllowed: 0,
            numTaken: 0,
            fileKey: "",
            error: null,
            uploading: false,
            displayOrder: pieces ? pieces.length : 0
        }]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(set)
        console.log(pieces)
        let submitData = {
            set: set,
            pieces: pieces,
        }

        submit(submitData, {
            method: "post",
            encType: "application/json",
        });
    }

    return (
        <Container className="bg-white d-flex flex-column rounded-3 mb-3" style={{maxWidth: "800px"}}>
            <Container className={"d-flex justify-content-center bg-light rounded-top-3"}>
                <h3 className={"mx-auto"}>Edit Flash Set</h3>
            </Container>
            {loaderData.set &&
                <Form className={""} onSubmit={handleSubmit}>
                    <Stack className={"d-flex mb-3"} gap={3}>
                        <hr style={{height: "3px", borderWwidth: "0", color: "gray", backgroundColor: "gray"}}/>

                        <h4 className={""}>Flash Set</h4>
                        <hr style={{height: "3px", borderWwidth: "0", color: "gray", backgroundColor: "gray"}}/>

                        <FormControl type={"text"} name={"artist"} defaultValue={loaderData.set.artist} readOnly={true}
                                     hidden={true}/>
                        <FormControl type={"text"} name={"id"} defaultValue={loaderData.set.id} readOnly={true}
                                     hidden={true}/>
                        <FormGroup controlId={"name"}>
                            <FormLabel className={""}>Set Name</FormLabel>
                            <FormControl
                                type={"text"}
                                name={"name"}
                                value={set.name}
                                onChange={(e) => handleSetChange("name", e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel className={""}>Description</FormLabel>
                            <FormControl
                                as="textarea" rows={3}
                                type={"text"}
                                name={"setDescription"}
                                value={set.setDescription}
                                onChange={(e) => handleSetChange("setDescription", e.target.value)}
                            />
                        </FormGroup>
                        <Stack direction={"horizontal"} gap={3}>

                            <FormGroup className={"w-50"}>
                                <FormLabel className={""}>Start Date</FormLabel>
                                <FormControl
                                    type={"date"}
                                    name={"startDate"}
                                    value={set.startDate}
                                    onChange={(e) => handleSetChange("startDate", e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup className={"w-50"}>
                                <FormLabel className={""}>End Date</FormLabel>
                                <FormControl
                                    type={"date"}
                                    name={"endDate"}
                                    value={set.endDate}
                                    onChange={(e) => handleSetChange("endDate", e.target.value)}
                                />
                            </FormGroup>
                        </Stack>
                        <FormCheck
                            id={"allSamePrice"}
                            label={"All Same Price"}
                            checked={set.allSamePrice}
                            onChange={(e) =>
                                handleSetChange("allSamePrice", e.target.checked)
                            }
                        />
                        <Stack direction={"horizontal"} gap={3}>
                            <FormGroup className={"w-50"}>
                                <FormLabel className={""}>Price</FormLabel>
                                <FormControl
                                    type="number"
                                    name={"price"}
                                    value={set.price}
                                    onChange={(e) => {
                                        handleSetChange("price", e.target.value)
                                        setAllPieceValues("price", e.target.value)
                                    }}
                                    disabled={!set.allSamePrice}
                                />
                            </FormGroup>
                            <FormGroup className={"w-50"}>
                                <FormLabel className={""}>Deposit</FormLabel>
                                <FormControl
                                    type="number"
                                    name={"deposit"}
                                    value={set.deposit}
                                    onChange={(e) => {
                                        handleSetChange("deposit", e.target.value)
                                        setAllPieceValues("deposit", e.target.value)
                                    }}
                                    disabled={!set.allSamePrice}
                                />
                            </FormGroup>
                        </Stack>
                        <FormCheck
                            id={"multiTattooDeal"}
                            label={"Multi-Piece Deal"}
                            checked={set.multiTattooDeal}
                            onChange={(e) => {
                                handleSetChange("multiTattooDeal", e.target.checked);
                                setAllPieceValues("multiTattooDeal", e.target.checked);
                            }}
                        />
                        <FormGroup className={"w-50"}>
                            <FormLabel className={""}>Price for Additional Pieces</FormLabel>
                            <FormControl
                                type="number"
                                name={"multiTattooPrice"}
                                value={set.multiTattooPrice}
                                onChange={(e) => {
                                    handleSetChange("multiTattooPrice", e.target.value);
                                    setAllPieceValues("multiTattooPrice", e.target.value);
                                }}
                                disabled={!set.multiTattooDeal}
                            />
                        </FormGroup>
                        <FormGroup className={""}>
                            <FormLabel className={""}>Deal Text</FormLabel>
                            <FormControl
                                type="number"
                                as="textarea" rows={3}
                                name={"dealText"}
                                value={set.dealText}
                                onChange={(e) => {
                                    handleSetChange("dealText", e.target.value);
                                }}
                                disabled={!set.multiTattooDeal}
                            />
                        </FormGroup>
                        <hr style={{height: "3px", borderWwidth: "0", color: "gray", backgroundColor: "gray"}}/>
                        <h4 className={""}>Flash Pieces</h4>
                        <hr style={{height: "3px", borderWwidth: "0", color: "gray", backgroundColor: "gray"}}/>
                        {pieces.map((piece, index) => (
                            <Container key={index}>
                                <Stack direction={"horizontal"} gap={3}>
                                    <h5> Piece {index + 1}</h5>
                                    <Button type={"button"} className={"btn-sm"}
                                            onClick={e => deletePieceAtIndex(index)}>
                                        X
                                    </Button>
                                </Stack>
                                <FormControl type={"text"} name={"artist"} defaultValue={piece.artist} readOnly
                                             plaintext hidden/>
                                <FormControl type={"text"} name={"id"} defaultValue={piece.id} readOnly
                                             plaintext hidden/>
                                <FormControl type={"text"} name={"setID"} defaultValue={piece.setID} readOnly
                                             plaintext hidden/>
                                <FormGroup>
                                    <FormLabel className={""}>Name of Piece</FormLabel>
                                    <FormControl
                                        type="text"
                                        name={"name"}
                                        value={piece.name}
                                        onChange={(e) => handleInputChange(index, "name", e.target.value)}
                                    />
                                </FormGroup>
                                <Stack direction={"horizontal"} gap={3}>
                                    <FormGroup className={"w-50"}>
                                        <FormLabel className={"w-50"}>Minutes Per Piece</FormLabel>
                                        <FormControl
                                            type="number"
                                            name={"durationMinutes"}
                                            value={piece.durationMinutes}
                                            onChange={(e) =>
                                                handleInputChange(index, "durationMinutes", e.target.value)
                                            }
                                        />
                                    </FormGroup>
                                    <FormGroup className={"w-50"}>
                                        <FormLabel className={"w-50"}>Display Order</FormLabel>
                                        <FormControl
                                            type="number"
                                            name={"displayOrder"}
                                            value={piece.displayOrder}
                                            onChange={(e) =>
                                                handleInputChange(index, "displayOrder", e.target.value)
                                            }
                                        />
                                    </FormGroup>
                                </Stack>
                                <Stack direction={"horizontal"} gap={3}>
                                    <FormGroup className={"w-50"}>
                                        <FormLabel className={"w-50"}>Price</FormLabel>
                                        <FormControl
                                            type="number"
                                            name={"price"}
                                            value={set.allSamePrice ? set.price : piece.price}
                                            onChange={(e) =>
                                                handleInputChange(index, "price", e.target.value)
                                            }
                                            disabled={set.allSamePrice}
                                        />
                                    </FormGroup>
                                    <FormGroup className={"w-50"}>
                                        <FormLabel>Deposit Amount</FormLabel>
                                        <FormControl
                                            type="number"
                                            name={"deposit"}
                                            value={set.allSamePrice ? set.deposit : piece.deposit}
                                            onChange={(e) =>
                                                handleInputChange(index, "deposit", e.target.value)
                                            }
                                            disabled={set.allSamePrice}
                                        />
                                    </FormGroup>
                                </Stack>

                                <Stack direction={"horizontal"} gap={3}>
                                    <FormGroup className={"w-50"}>
                                        <FormCheck
                                            id={"limited"}
                                            label={"Limited?"}
                                            checked={piece.limited}
                                            onChange={(e) =>
                                                handleInputChange(index, "limited", e.target.checked)
                                            }
                                        />
                                    </FormGroup>
                                    <FormGroup className={"w-50"}>
                                        <FormLabel className={""}>Number Allowed</FormLabel>
                                        <FormControl
                                            type="number"
                                            name={"numAllowed"}
                                            value={piece.numAllowed}
                                            onChange={(e) =>
                                                handleInputChange(index, "numAllowed", e.target.value)
                                            }
                                            disabled={!piece.limited}
                                        />
                                    </FormGroup>
                                </Stack>

                                <FormControl type={"text"} name={"fileKey"} defaultValue={piece.fileKey} readOnly={true}
                                             hidden={true}/>

                                <h5>Image</h5>
                                {piece.err &&
                                    <p className={"text-danger"}>Error uploading image. See console for log.</p>
                                }
                                {piece.fileKey &&
                                    <>
                                        <p><strong>Image File Key:</strong> {piece.fileKey}</p>
                                        <Button type={"button"}
                                                onClick={e => {
                                                    handleInputChange(index, "fileKey", "")
                                                }}>
                                            Remove Image
                                        </Button>
                                    </>
                                }
                                {!piece.fileKey &&
                                    <FormGroup>
                                        <FormLabel>Upload Image</FormLabel>
                                        <FormControl
                                            type="file"
                                            name={"newFile"}
                                            onChange={(e) => {
                                                handleImageUpload(index, e.target.files[0])
                                            }}
                                        />
                                    </FormGroup>
                                }

                                <hr style={{height: "3px", borderWwidth: "0", color: "gray", backgroundColor: "gray"}}/>

                            </Container>

                        ))}
                        <Button className={"mx-auto w-50"} type="button" onClick={addPiece}>
                            + Add Piece
                        </Button>
                        <div className={"d-flex column-gap-3 justify-content-end"}>
                            <Button className={"btn-secondary"} type={"button"} onClick={cancelEdit}>Cancel</Button>
                            <Button type={"submit"}>Submit Changes</Button>
                        </div>
                    </Stack>
                </Form>
            }
        </Container>
    );
}