import Container from "react-bootstrap/Container";
import React, {FormControl, FormGroup, FormLabel} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {useFormContext} from "react-hook-form";


export default function LoginForm({onSubmit}) {
    const {register} = useFormContext();

    return (
        <Form noValidate onSubmit={onSubmit} className="p-3 form-bg">
            <Container
                className="bg-success d-flex flex-column justify-content-center text-light mb-3 rounded-3 p-2">
                <div className={"h1 mx-auto my-2"}>
                    Admin Login
                </div>
                <FormGroup className="mb-3" controlId="username">
                    <FormLabel>Username</FormLabel>
                    <FormControl name="username" {...register("username", {
                        // required: "username required"
                    })}/>
                </FormGroup>
                <FormGroup className="mb-3" controlId="password">
                    <FormLabel>Password</FormLabel>
                    <FormControl autoComplete="current-password" type="password"
                                 name="password" {...register("password", {
                        // required: "password required"
                    })}/>

                </FormGroup>
                <Button variant="primary" type="submit" className="mb-3 ">
                    Submit
                </Button>
            </Container>
        </Form>
    );
}