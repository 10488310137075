import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import shopPic from "../../assets/images/billc-shop-floor.jpg";
export function TempHome() {
    return (
        <Container fluid className={"background-container d-flex flex-column flex-grow-1"}>
            <Container className={"bg-success d-flex flex-column flex-grow-1 text-light rounded-3 p-3"}>
                <h1 className={"mx-auto"}>We're Open!</h1>
                <div className={"mx-auto"}>
                    <p>
                        It's been a long wait and a wild ride, but we are finally ready open things up!
                    </p>
                    <p>
                        Our booking form is available through the "Book Now" button above, and our FAQ page is packed with
                        information and guidance should you need it. Some parts of the site are still under construction,
                        so we apologize if you experience any hickups as we work to iron things out.
                    </p>
                    <p>
                        Thanks again for your patience and support. See you soon!
                    </p>
                </div>
                <Image
                    className=""
                    src={shopPic}
                    style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                    }}
                    alt="Picture of the shop"
                />
            </Container>
        </Container>
    );
}