import React from "react-bootstrap";
import "../../../assets/styles/Forms.css";

export default function RequiredText({isMissing}) {

    let style = isMissing ? "validation-text" : "validation-error-text";

    return (
        <span className={style}>(required)</span>
    );
}