import '../../assets/styles/global.css';
import Artist from "../../model/Artist";
import calistaPic from "../../assets/images/cali-pose-1.jpg";
import erikaPic from "../../assets/images/erika-pose-1.jpg";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import {FaInstagram} from 'react-icons/fa';

const artists = new Map([
    ["erika", new Artist(
        "Erika Holmberg",
        "Fine Line Tattoo Artist",
        "https://www.instagram.com/erika.tattoo.art/",
        ["Erika specializes in fine-line small/medium-sized black & white tattoos, and occasionally takes small requests for color. Her favorite subjects/styles to tattoo include insects, birds, folk art, ornamental, and houseplants",
        "Erika tries not to take life too seriously and is pretty goofy and easy going with her clients. In her personal life, she has a Mini-Aussie/Mini-Husky pup named Eevee, and loves to travel, fly hot air balloons, go to concerts, and get outdoors. She has been doing art since she can remember, which has always been inspired by her mother, who is a painter.",
        "Erika got into tattooing three and a half years ago, and loves her career and her clients dearly. She is passionate about the safety of her clients and making sure they can receive a tattoo they are in love with when they leave."],
        erikaPic)],
    ["calista", new Artist(
        "Calista Doran",
        "Fine Line Tattoo Artist",
        "https://www.instagram.com/tattoos.by.calista/?img_index=1",
        ["Calista is a fine line tattoo artist that currently only does black and white tattoos, but is open to discussing any ideas you might have. She loves doing micro tattoos and simple minimalistic script. Her favorite subjects to tattoo include anything Studio Ghibli, silly tattoos, small insects (i.e., ladybugs and bees), and abstract pieces.",
        "Calista has always been passionate about art in many forms, as seen through a passion in ceramics and a bachelor’s degree in art history and art museum studies. She is always striving to learn new things and travel as much as possible. On her days off you can find Calista sitting in a hammock on the Boise river, crocheting at a local coffee shop, or planning her next adventure!",
        "Being a tattoo artist is something that Calista is very passionate about and loves the people she meets through her career. Being able to create a unique piece together with her clients and share her art is the reason she loves what she does!"],
        calistaPic)]
]);

export default function Artists({artistName}) {
    let artist = artists.get(artistName);
    return (
        <Container className="d-flex flex-wrap column-gap-3 row-gap-3">

                <Container
                    className="d-flex flex-column align-items-center bg-success text-light rounded-3 p-3"
                    style={{
                        maxWidth: "400px",
                        maxHeight: "auto"
                    }}
                >
                    <h2>{artist.name}</h2>
                    <Image
                        className="rounded-circle"
                        src={artist.profilePic}
                        style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                        }}
                        alt="Artist profile picture"
                    />

                    <p>{artist.title}</p>
                    <div>
                        <a className={"mx-auto mb-3 text-light"} href={artist.instagram}>
                            <FaInstagram size={"40px"} className={"mx-2"}/>
                            {artist.name}'s Instagram
                        </a>
                    </div>
                </Container>
                <Container
                    className="d-flex flex-grow-1 flex-column align-items-center bg-primary text-light rounded-3 p-3"
                >
                    <h2>Artist Bio</h2>
                    {artist.bio.map((paragraph, index) => (
                        <p key={"p"+index}>{paragraph}</p>
                        ))
                    }
                </Container>
        </Container>
    );
}
