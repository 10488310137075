import {isRouteErrorResponse, useRouteError} from "react-router-dom";


export default function ErrorPage() {
    const error = useRouteError();
    console.log(error)
    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            return <div>404 - The appointment or page you are looking for cannot be found here</div>;
        }

        if (error.status === 401) {
            return <div>401 - You aren't authorized to see this!</div>;
        }

        if (error.status === 503) {
            return <div>503 - Our website is down and Josh is panicking. Try again in a little while.</div>;
        }

        if (error.status === 418) {
            return <div>wut?</div>;
        }

        return <div>500 - Something went wrong. I'm sorry dude.</div>;

    }

    return <div>500 - Something went wrong. I'm sorry. </div>;
}