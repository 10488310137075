import {Outlet, useOutlet} from "react-router-dom";
import React from "react";
import {AuthProvider} from "./AuthContext";
import Container from "react-bootstrap/Container";

export default function AuthLayout() {
    return (
        <AuthProvider>
            <Outlet/>
        </AuthProvider>
    );
}