import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useAuthContext} from "./AuthContext";
import LoadingPage from "../util/LoadingPage";

export const ProtectedLayout = () => {
    const authContext = useAuthContext();

    if (!authContext || authContext.loading) {
        return (<LoadingPage/>);
    }

    return authContext.userData && authContext.userData.authenticated ? (

        <Outlet/>

    ) : (
        <Navigate to="login" replace state={{from: window.location.pathname}}/>
    );
};