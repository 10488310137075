import {FormCheck, FormGroup} from "react-bootstrap";
import {useFormContext} from "react-hook-form";
import Container from "react-bootstrap/Container";


export default function Over18Checkbox({hasBuddy = false}) {
    const {
        register,
        // control,
        formState: {
            errors,
        }
    } = useFormContext();

    return (
        <Container className="d-flex flex-column">
            <FormGroup className="w-auto mx-auto">
                <FormCheck
                    type="checkbox"
                    id="over18"
                    label={hasBuddy
                        ? "I certify that both persons are over the age of 18"
                        : "I certify that I am over the age of 18"}
                    {...register("over18", {
                        required: hasBuddy
                            ? "Both persons must be 18 or older to book an appointment"
                            : "You must be 18 or older to book an appointment"
                    })}
                    isInvalid={errors && errors.over18}
                />
            </FormGroup>
            <p className="mx-4 text-light small" style={{textAlign: "start"}}>
                Clients must be 18+ and present valid government-issued ID on appointment day.
                Failure to do so will cancel the appointment and forfeit any deposit.
                This strict policy ensures legal compliance and safety.
            </p>
        </Container>
    );
}