import {FormProvider, useForm} from "react-hook-form";

import ResetForm from "./ResetForm";
import LoginForm from "./LoginForm";
import {useAuthContext} from "./AuthContext";
import React from "react-bootstrap";
import LogoutForm from "./LogoutForm";
import Container from "react-bootstrap/Container";
import {useNavigate, useNavigation} from "react-router-dom";


export default function AdminLogin() {
    const auth = useAuthContext();
    const methods = useForm({});
    const navigate = useNavigate()

    const loginUser = async (data) => {
        let {username, password} = data;

        await auth.doLogin(username, password);
        console.log(auth)
    };

    const handleNewPassword = async (event) => {
        console.log("handling new password");
        let newPassword = event.newPassword;
        await auth.setNewPassword(newPassword);
        navigate("/admin")
    };

    const logoutUser = async () => {
        await auth.doLogout();
    };

    return (
        <Container fluid className={"my-3"}>

            <FormProvider {...methods} >
                {auth.userData && auth.userData.requiresReset &&
                    <ResetForm onSubmit={methods.handleSubmit(handleNewPassword)}/>
                }
                {auth.userData && auth.userData.authenticated &&
                    <LogoutForm username={auth.userData.username} action={logoutUser}/>
                }
                {(!auth.userData || (auth.userData && !auth.userData.authenticated)) &&
                    <LoginForm onSubmit={methods.handleSubmit(loginUser)}/>
                }
                {methods.formState.errors?.password && <p>{methods.formState.errors.password.message}</p>}
                {methods.formState.errors?.username && <p>{methods.formState.errors.username.message}</p>}
                {methods.formState.errors?.newPassword && <p>{methods.formState.errors.username.newPassword}</p>}
            </FormProvider>
        </Container>
    );
}

