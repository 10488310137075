import Image from "react-bootstrap/Image";
import React, {FormCheck, FormGroup, FormLabel, Stack} from "react-bootstrap";
import erikaProfilePic from "../../../assets/images/erika-pose-1.jpg";
import calistaProfilePic from "../../../assets/images/cali-pose-1.jpg";
import {useFormContext, useWatch} from "react-hook-form";
import Container from "react-bootstrap/Container";
import {useState} from "react";


const artists = [
    {
        name: "erika",
        fullname: "Erika Holmberg",
        title: "Fine Line Tattoo Artist",
        picture: erikaProfilePic
    },
    {
        name: "calista",
        fullname: "Calista Doran",
        title: "Fine Line Tattoo Artist",
        picture: calistaProfilePic
    }
];

export default function ArtistSelect({setSelectedArtist}) {
    const {
        register,
        watch,
    } = useFormContext();

    const [current, setCurrent] = useState();

    const handleArtistSelect = (name) => {
        setSelectedArtist(name);
        setCurrent(name);
    }

    return (
        <Container className={"d-flex flex-column flex-lg-row mb-3 column-gap-3 row-gap-3"}>
            {artists.map((artistStruc) => (
                <div
                    className={`artist-select ${current === artistStruc.name ? "selected" : ""} 
                    flex-grow-1 justify-content-center p-2`}
                    key={artistStruc.name + "label"}>
                    <input
                        type="radio"
                        key={artistStruc.name}
                        id={artistStruc.name}
                        value={artistStruc.name}
                        name="artist"
                        className={"artist-select-radio"}
                        {...register("artist")}
                        onChange={(event) => handleArtistSelect(event.target.value)}
                    />
                    <label className="d-flex" style={{cursor: "pointer"}} htmlFor={artistStruc.name}>
                        <Image
                            key={artistStruc.name + "img"}
                            src={artistStruc.picture}
                            alt={artistStruc.fullname}
                            height={"100px"}
                            width={"100px"}
                            className={`rounded-circle m-auto artist-image ${current === artistStruc.name ? "selected" : ""}`}
                        />
                        <Stack className="m-auto" style={{minWidth: "200px"}}>
                            <div className="h4 mx-auto">{artistStruc.fullname}</div>
                            <div className="mx-auto" key={artistStruc.name + "tit"}>{artistStruc.title}</div>
                        </Stack>
                    </label>
                </div>
            ))}
        </Container>
    );
}