import Container from "react-bootstrap/Container";
import {json, redirect, useFetcher, useLoaderData} from "react-router-dom";

const appointmentAdminLoader = (getAuth) => async () => {
    console.log("appointment admin loader")
    try {
        let auth = await getAuth();
        let artist = auth.username === "jockerse" ? "erika" : auth.username;
        let response = await fetch(`https://botanical.ink/api/admin/${artist}/settings`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.idToken.getJwtToken()}`,
            },
        });
        if (response.ok) {
            return await response.json();
        } else {
            return json({
                error: response.status
            })
        }
    } catch (error) {
        console.log(error)
        if (error === "NOT VALID") {
            return redirect("/login")
        } else {
            throw error
        }
    }
}

const appointmentAdminAction = (getAuth) => async () => {

}

export const AppointmentAdmin = () => {
    const loaderData = useLoaderData();
    const fetcher = useFetcher();

    return (
        <Container className={"d-flex justify-content-center mb-3"}>
            <h3>Appointments</h3>
            <Container >

            </Container>
        </Container>
    );
}